import { Component, OnInit } from '@angular/core';
import { AllapiService } from 'src/app/admin/server/allapi.service';

@Component({
  selector: 'app-leftsidebar',
  templateUrl: './leftsidebar.component.html',
  styleUrls: ['./leftsidebar.component.css']
})
export class LeftsidebarComponentAdmin implements OnInit {
  value: any;

  constructor(public server: AllapiService) {
    this.server.activeCast.subscribe((res) => {
      this.value = res;
    })
  }

  ngOnInit() {
  }
  setHeader(data) {
    this.server.header.next(data) //this is used for set
  }

}
