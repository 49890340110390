import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// ----------------http
import { HttpClientModule } from '@angular/common/http';
// ----------------toastr
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// -------------------Module
import { PlayermoduleModule } from './player/playermodule/playermodule/playermodule.module';
import { AdminmoduleModule } from './admin/adminmodule/adminmodule/adminmodule.module';
import { AgentmoduleModule } from './agent/agentmodule/agentmodule/agentmodule.module';
import { IccModule } from './icc/iccmodule/icc/icc.module';
import { Gt20Module } from './gt20canada/gt20module/gt20/gt20.module';
//--------------------Interceptor
import { HttpInterceptorData } from './autherization/tokenintercept';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({ positionClass: 'toast-top-center' }),
    PlayermoduleModule,
    AdminmoduleModule,
    AgentmoduleModule,
    IccModule,
    Gt20Module
  ],
  providers: [HttpInterceptorData, { provide: ToastrService, useClass: ToastrService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
