import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-playerfooter',
  templateUrl: './playerfooter.component.html',
  styleUrls: ['./playerfooter.component.css']
})
export class PlayerfooterComponent implements OnInit {
  currentYear: number = new Date().getFullYear();
  constructor() { }

  ngOnInit() {
  }

}
