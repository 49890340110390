import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/autherization/server/api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthgaurdGuard implements CanActivate {

  constructor(private _server: ApiService, private _router: Router) { }

  canActivate(): boolean {
    if (this._server.loggedIn()) {
      return true
    } else {
      this._router.navigate(['/registration'])
      return false
    }
  }

}
