import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment'
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AllapiService {
  baseurl = environment.baseurl;

  public header: BehaviorSubject<any> = new BehaviorSubject(0); //it is called subject behavior for header
  public headerCast = this.header.asObservable()

  public active: BehaviorSubject<any> = new BehaviorSubject(0); //it is called subject behavior for active
  public activeCast = this.active.asObservable()

  constructor(public http: HttpClient, public route: Router) { }

  postApi(url, data): Observable<any> {
    return this.http.post(this.baseurl + url, data)
  }

  getApi(url): Observable<any> {
    return this.http.get(this.baseurl + url)
  }

  goTo(route) {
    this.route.navigate([route]);
  }
}
