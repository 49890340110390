import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthserviceService } from '../../serviceauth/authservice.service';

@Component({
  selector: 'app-iccheader',
  templateUrl: './iccheader.component.html',
  styleUrls: ['./iccheader.component.css']
})
export class IccheaderComponent implements OnInit {
  valueData: any;

  constructor(public _authentication: AuthserviceService, private _router: Router) {
    this._authentication.activeCastSideicc.subscribe((data) => {
      this.valueData = data;
    })
  }
  ngOnInit() {
  }
  logout() {
    localStorage.clear();
    this._router.navigate(['/icclogin']);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }
  showMenu: boolean = false;
  tabOpen() {
    this.showMenu = !this.showMenu
  }
}
