import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agentfooter',
  templateUrl: './agentfooter.component.html',
  styleUrls: ['./agentfooter.component.css']
})
export class AgentfooterComponent implements OnInit {
  currentYear: number = new Date().getFullYear();
  constructor() { }

  ngOnInit() {
  }

}
