import { Component, OnInit } from '@angular/core';
import { AuthserviceService } from 'src/app/icc/serviceauth/authservice.service';
declare var $: any;
@Component({
  selector: 'app-gt20agentlist',
  templateUrl: './gt20agentlist.component.html',
  styleUrls: ['./gt20agentlist.component.css']
})
export class Gt20agentlistComponent implements OnInit {
  agentall;
  agentSearch;
  agentData: any = [];
  PlayerDataaddedbyAg: any = [];
  headerName: string;
  tr1: string;
  tr2: string;
  tr3: string;
  tr4: string;
  tr5: string;
  agentFullName: string;

  constructor(public _authentication: AuthserviceService) {
    this._authentication.activeSideBaricc.next('Agent List');
    this._authentication.activeSideBaricc.next("Agent List");
    this.agentFetch();
  }
  ngOnInit() {
  }
  agentFetch() {
    this._authentication.getMethodicc('M2').subscribe((data) => {
      if (data.responseCode == 200) {
        this.agentData = data.response;
      }
    })
  }
  viewPlayerDetails(data) {
    this.agentFullName = data.first_name + ' ' + data.last_name
    let allData = {
      agent: data.first_name + ' ' + data.last_name,
      ag_contact: data.agent_contact
    }
    this._authentication.postMethodicc('A8', allData).subscribe((data) => {
      if (data.responseCode == 200) {
        this.PlayerDataaddedbyAg = data.response;
        $('#PlayerModal').modal('show');
        this.headerName = "Player Profile";
        this.tr1 = "Player Name";
        this.tr2 = "Player Email";
        this.tr3 = "Country Name";
        this.tr4 = "Category";
        this.tr5 = "Player Proficiency";
      } else {
        $('#PlayerModal').modal('show');
        this.headerName = "There is no player registered by " + this.agentFullName;
        this.PlayerDataaddedbyAg = [];
      }
    })
  }
}
