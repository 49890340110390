import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Gt20headerComponent } from 'src/app/gt20canada/shared/gt20header/gt20header.component';
import { Gt20footerComponent } from 'src/app/gt20canada/shared/gt20footer/gt20footer.component';
import { Gt20leftsidebarComponent } from 'src/app/gt20canada/shared/gt20leftsidebar/gt20leftsidebar.component';
import { Gt20dashboardComponent } from 'src/app/gt20canada/page/gt20dashboard/gt20dashboard.component';
import { Gt20loginComponent } from 'src/app/gt20canada/page/gt20login/gt20login.component';
import { Gt20playerlistComponent } from 'src/app/gt20canada/page/gt20playerlist/gt20playerlist.component';
import { Gt20agentlistComponent } from 'src/app/gt20canada/page/gt20agentlist/gt20agentlist.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter'; //npm i ng2-search-filter Search data purpose



@NgModule({
  declarations: [
    Gt20headerComponent,
    Gt20footerComponent,
    Gt20leftsidebarComponent,
    Gt20dashboardComponent,
    Gt20loginComponent,
    Gt20playerlistComponent,
    Gt20agentlistComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
  ],
  exports: [
    Gt20headerComponent,
    Gt20footerComponent,
    Gt20leftsidebarComponent,
    Gt20dashboardComponent,
    Gt20loginComponent,
    Gt20playerlistComponent,
    Gt20agentlistComponent,
    ReactiveFormsModule,
    FormsModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
  ]
})
export class Gt20Module { }
