import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthserviceService } from 'src/app/icc/serviceauth/authservice.service';

@Component({
  selector: 'app-gt20leftsidebar',
  templateUrl: './gt20leftsidebar.component.html',
  styleUrls: ['./gt20leftsidebar.component.css']
})
export class Gt20leftsidebarComponent implements OnInit {
  valueData: any;

  constructor(public _authentication: AuthserviceService, private _router: Router) {
    this._authentication.activeCastSideicc.subscribe((data) => {
      this.valueData = data;
    })
  }

  ngOnInit() {
  }
  setHeaderDataAgent(data) {
    this._authentication.headerforicc.next(data) //this is used for set
  }
  logout() {
    localStorage.clear();
    this._router.navigate(['/cricketcanadalogin']);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }
}
