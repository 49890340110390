import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../auth/auth.service';
declare var $: any;
@Component({
  selector: 'app-agentprofile',
  templateUrl: './agentprofile.component.html',
  styleUrls: ['./agentprofile.component.css']
})
export class AgentprofileComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  nationalityName: any;
  agentData: any;
  agenID: any;
  agent_email: any;
  AgentFinaldata: any;
  constructor(private _authentication: AuthService, private formBuilder: FormBuilder, private toastr: ToastrService) {
    this._authentication.activeSideBaragent.next('Agent Profile');
    this._authentication.activeSideBaragent.next("Agent Profile");
  }

  ngOnInit() {
    this.agentRegisterform();
    this.countrySelected();
    this.fetchAgent();
  }

  fetchAgent() {
    this.agentData = JSON.parse(localStorage.getItem('agentUser'));
    this.agenID = this.agentData.ID;
    this.agent_email = this.agentData.agent_email;
    let data = {
      agentId: this.agenID,
      agentEmail: this.agent_email
    }
    this._authentication.postMethod('A6', data).subscribe((value) => {
      if (value.responseCode == 200) {
        this.AgentFinaldata = value.response;
        this.registerForm.setValue({
          first_name: this.AgentFinaldata[0].first_name,
          last_name: this.AgentFinaldata[0].last_name,
          agent_email: this.AgentFinaldata[0].agent_email,
          agent_contact: this.AgentFinaldata[0].agent_contact,
          agent_org_name: this.AgentFinaldata[0].agent_org_name,
          agent_adddress: this.AgentFinaldata[0].agent_adddress,
        });
        $("#country_selector").val(this.AgentFinaldata[0].agent_countryName);
      }
    })
  }

  countrySelected() {
    $("#country_selector").countrySelect({
      preferredCountries: ['ca', 'gb', 'us', 'in']
    });
    $('#country_selector').change(function () {
      var country = $('#country_selector').val();
      var countrylower = country.toLowerCase();
      var international = ["south africa", "zimbabwe", "west indies", "afghanistan", "bangladesh", "england", "pakistan", "sri lanka", "australia", "new zealand", "india", "ireland"];
      var iccamericas = ["argentina", "bermuda", "cayman islands", "falkland islands", "peru", "united states", "bahamas", "brazil", "chile", "mexico", "suriname", "belize", "costa rica", "panama", "turks and caicos islands"];
      var canadanational = ["canada"];
      this.a_international = international.indexOf(countrylower);
      this.b_iccamericas = iccamericas.indexOf(countrylower);
      this.c_canadanational = canadanational.indexOf(countrylower);
      if (this.a_international != '-1') {
        $("#selcategory").val("INTERNATIONAL");
      }
      else if (this.b_iccamericas != '-1') {
        $("#selcategory").val("ICC AMERICAS");
      }
      else if (this.c_canadanational != '-1') {
        $("#selcategory").val("CANADA NATIONAL");
      }
      else {
        $("#selcategory").val("ICC ASSOCIATES");
      }
    });
  }


  agentRegisterform() {
    this.registerForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      agent_email: ['', [Validators.required, Validators.email]],
      agent_contact: ['', Validators.required],
      agent_org_name: ['', Validators.required],
      agent_adddress: ['', Validators.required],
    });
  }

  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.nationalityName = $('#country_selector').val();
    Object.assign(this.registerForm.value, { "agent_countryName": this.nationalityName, "agentID": this.agenID });
    if (this.registerForm.invalid) {
      return;
    } else {
      // console.log(this.registerForm.value)
      // return;
      if (this.nationalityName) {
        this._authentication.postMethod('A7', this.registerForm.value).subscribe((data) => {
          if (data.responseCode == 200) {
            this.toastr.success(data['responseMessage']);
            this.fetchAgent();
          }
          else {
            this.toastr.error(data['responseMessage']);
          }
        })
      } else {
        this.toastr.warning("Please Select Country Name!");
      }
    }
  }

}
