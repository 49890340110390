import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthserviceService } from 'src/app/icc/serviceauth/authservice.service';
import { validation } from '../../../pattern'

declare var $:any;
@Component({
  selector: 'app-gt20dashboard',
  templateUrl: './gt20dashboard.component.html',
  styleUrls: ['./gt20dashboard.component.css']
})
export class Gt20dashboardComponent implements OnInit {
  playerData: any = [];
  agentData: any = [];
  contactForm: FormGroup;

  constructor(public _authentication: AuthserviceService, private toastr: ToastrService) {
    this._authentication.activeSideBaricc.next('Dashboard');
    this._authentication.activeSideBaricc.next("Dashboard");
    this.contactForm = new FormGroup({
      firstname: new FormControl('', [Validators.required, Validators.pattern(validation.name)]),
      lastName: new FormControl('', [Validators.required, Validators.pattern(validation.name)]),
      email: new FormControl('', [Validators.required, Validators.pattern(validation.email)]),
      phone: new FormControl('', [Validators.required, Validators.pattern(validation.number)]),
      message: new FormControl('', [Validators.required])
    })
  }
  get f() {
    return this.contactForm.controls;
  }
  ngOnInit() {
    this.playerFetch();
    this.agentFetch();
    this.validation();
  }

  onSubmit() {
    if (this.contactForm.invalid) {
      Object.keys(this.contactForm.controls).forEach(field => {
        const control = this.contactForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
    else {
      this._authentication.postMethodicc('P22', this.contactForm.value).subscribe((res) => {
        if (res.responseCode == 200) {
          this.toastr.success(res['responseMessage']);
          this.contactForm.reset();
        }
        else {
          this.toastr.error(res['responseMessage']);
        }
      })
    }
  }
  validation() {
    $("#firstname,#lastName").keypress(function (e) {
      var keyCode = e.which;
      if (!((keyCode >= 65 && keyCode <= 90)
        || (keyCode >= 97 && keyCode <= 122))
        && keyCode != 8 && keyCode != 32) {
        e.preventDefault();
      }
    });
    $("#phone").keypress(function (e) {
      var keycode = e.which;
      if (!(keycode >= 48 && keycode <= 57)) {
        e.preventDefault();
      }
    });
  }
  playerFetch() {
    this._authentication.getMethodicc('M1').subscribe((data) => {
      if (data.responseCode == 200) {
        this.playerData = data.response;
      }
    })
  }
  agentFetch() {
    this._authentication.getMethodicc('M2').subscribe((data) => {
      if (data.responseCode == 200) {
        this.agentData = data.response;
      }
    })
  }
}
