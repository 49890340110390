import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiService } from '../server/api.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptService implements HttpInterceptor {

  constructor(private injector: Injector, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log(req.url)
    let authService = this.injector.get(ApiService)
    const tokenizedReq = req.clone({
      setHeaders: {
        authorization: `${authService.getToken()}`
      }
    })
    // console.log(next.handle(tokenizedReq)) //https://lironhazan.medium.com/angular-6-401-authentication-error-handling-888922def566
    // return next.handle(tokenizedReq);
    return next.handle(tokenizedReq).pipe(tap(() => { },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status !== 401) {
            return;
          }
          this.router.navigate(['registration']);
          localStorage.clear();
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      }));
  }
}