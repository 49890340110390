import { Injectable } from '@angular/core';
import { environment } from '../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  baseurl: any = environment.baseurl;
  // ==========S3 Img Url==============
  dashboardSlider: string = "https://gt20webstorage.s3.ap-south-1.amazonaws.com/Dashboard_Slider/";
  partnerSlider: string = "https://gt20webstorage.s3.ap-south-1.amazonaws.com/Dashboard_Partner/";
  mixed: string = "https://gt20webstorage.s3.ap-south-1.amazonaws.com/Mixed/"
  mediaNews: string = "https://gt20webstorage.s3.ap-south-1.amazonaws.com/Media_News/"
  mediaPicture: string = "https://gt20webstorage.s3.ap-south-1.amazonaws.com/Media_Picture/"
  playerImage: string = "https://gt20webstorage.s3.ap-south-1.amazonaws.com/Registration/p_image/";
  passportScan: string = "https://gt20webstorage.s3.ap-south-1.amazonaws.com/Registration/passport_scanned/";
  nocCopy: string = "https://gt20webstorage.s3.ap-south-1.amazonaws.com/Registration/noc_copy/";
  visaCopy: string = "https://gt20webstorage.s3.ap-south-1.amazonaws.com/Registration/visa_copy/";
  // ================S3 Url===============

  constructor() {
    this.baseurl = environment.baseurl;
  }
}
