import { Component, OnInit } from '@angular/core';
import { AuthserviceService } from '../../serviceauth/authservice.service';
import { CommonService } from 'src/app/common.service';
declare var $: any;

@Component({
  selector: 'app-allplayerlist',
  templateUrl: './allplayerlist.component.html',
  styleUrls: ['./allplayerlist.component.css']
})
export class AllplayerlistComponent implements OnInit {
  playerall;
  playerSearch;
  imgUrl: any;
  headerName: string;
  tr1: string;
  tr2: string;
  tr3: string;
  tr4: string;
  tr5: string;
  td1: any;
  td2: any;
  td3: any;
  td4: any;
  td5: any;
  playerData: any[];
  constructor(public _authentication: AuthserviceService, private comS: CommonService) {
    this._authentication.activeSideBaricc.next('Player List');
    this._authentication.activeSideBaricc.next("Player List");
    this.imgUrl = this.comS.playerImage;
    this.playerFetch();
  }

  ngOnInit() {
  }
  viewDetails(allData, actionView) {
    $('#viewModal').modal('show');
    if (actionView == 'playerProfile') {
      this.headerName = "Player Profile";
      this.tr1 = "Proficiency";
      this.tr2 = "Bat Hand";
      this.tr3 = "Bowl Hand";
      // this.tr4 = "Batting Order";
      this.tr5 = "Bowl Style";

      this.td1 = allData.p_proficiency;
      this.td2 = allData.batting_hand;
      this.td3 = allData.bowlling_hand;
      // this.td4 = allData.batting_order;
      this.td5 = allData.bowlling_style;
    }
    // if (actionView == 'bankDetails') {
    //   this.headerName = "Bank details";
    //   this.tr1 = "Bank Name";
    //   this.tr2 = "Account Number";
    //   this.tr3 = "Swift Code";
    //   this.tr4 = "Intermediate Bank";
    //   this.tr5 = "Bank Address";

    //   this.td1 = allData.bank_name;
    //   this.td2 = allData.acc_number;
    //   this.td3 = allData.swift_code;
    //   this.td4 = allData.intr_bank;
    //   this.td5 = allData.bank_address;
    // }
    if (actionView == 'jersyDetails') {
      this.headerName = "Player kit";
      this.tr1 = "Jersey Size	";
      this.tr2 = "Jersey Number";
      this.tr3 = "Sleeves Preference";
      this.tr4 = "Name On Jersey";
      this.tr5 = "Track size";

      this.td1 = allData.jersey_size;
      this.td2 = allData.jersey_number;
      this.td3 = allData.jersey_sleeves;
      this.td4 = allData.jersey_name;
      this.td5 = allData.track_size;
    }
  }
  playerFetch() {
    this._authentication.getMethodicc('M1').subscribe((data) => {
      if (data.responseCode == 200) {
        this.playerData = data.response;
        // console.log(this.playerData)
      }
    })
  }
}
