import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AllapiService } from 'src/app/admin/server/allapi.service';
import { version } from '../../../../../package.json';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponentAdmin implements OnInit {
  headerValue: any = 'Dashboard';
  releaseVersion: string | undefined = undefined;

  constructor(public server: AllapiService, private router: Router) {
    this.releaseVersion = version;
    this.server.headerCast.subscribe((res) => {
      this.headerValue = res
    })
  }

  ngOnInit() {
  }
  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }
  showMenu: boolean = false;
  tabOpen() {
    this.showMenu = !this.showMenu
    // console.log(this.showMenu)
  }

}
