import { Component, OnInit } from '@angular/core';
import { AuthserviceService } from '../../service/authservice.service';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-playerprofile',
  templateUrl: './playerprofile.component.html',
  styleUrls: ['./playerprofile.component.css']
})
export class PlayerprofileComponent implements OnInit {
  allPlayer: any = [];
  PlayerImage: any;
  p_proficiency: any;
  p_dob: any;
  bowlling_hand: any;
  batting_hand: any;
  bowlling_style: any;
  batting_order: any;
  last_name: any;
  first_name: any;
  selcategory: any;
  reserve_price: any;
  imgSrc: string;
  p_email: any;
  p_contact: any;
  alternate_contact: any;
  nationality: any;
  passport_no: any;
  visa_status: any;
  bank_address: any;
  intr_bank: any;
  swift_code: any;
  acc_number: any;
  bank_name: any;
  corres_bank_name: any;
  corres_acc_number: any;
  corres_bank_address: any;
  track_length: any;
  track_size: any;
  jersey_number2: any;
  jersey_sleeves: any;
  country_birth: any;
  foreign_tax_num: any;
  jersey_size: any;
  jersey_name: any;
  jersey_number: any;
  jersey_number3: any;
  player_address: any;

  constructor(private _auth: AuthserviceService, private comS: CommonService) {
    this._auth.activeSideBar.next('Player Profile');
    this._auth.activeSideBar.next("Player Profile");
  }

  ngOnInit() {
    this.imgSrc = this.comS.playerImage;
    this.fetchPlayerData();
  }
  fetchPlayerData() {
    var data = JSON.parse(localStorage.getItem('currentUser'));
    this._auth.postApiMethod('A4', data).subscribe((res) => {
      if (res.responseCode == 200) {
        this.allPlayer = res.response;
        // console.log(this.allPlayer)
        this.PlayerImage = this.allPlayer[0].p_image;
        this.p_proficiency = this.allPlayer[0].p_proficiency;
        this.reserve_price = this.allPlayer[0].reserve_price;
        this.selcategory = this.allPlayer[0].selcategory;
        this.first_name = this.allPlayer[0].first_name;
        this.last_name = this.allPlayer[0].last_name;
        this.batting_order = this.allPlayer[0].batting_order;
        this.bowlling_style = this.allPlayer[0].bowlling_style;
        this.batting_hand = this.allPlayer[0].batting_hand;
        this.bowlling_hand = this.allPlayer[0].bowlling_hand;
        this.p_dob = this.allPlayer[0].p_dob;
        this.p_email = this.allPlayer[0].p_email;
        this.p_contact = this.allPlayer[0].p_contact;
        this.alternate_contact = this.allPlayer[0].alternate_contact;
        this.nationality = this.allPlayer[0].nationality;
        this.passport_no = this.allPlayer[0].passport_no;
        this.visa_status = this.allPlayer[0].visa_status;
        this.bank_address = this.allPlayer[0].bank_address;
        this.intr_bank = this.allPlayer[0].intr_bank;
        this.swift_code = this.allPlayer[0].swift_code;
        this.acc_number = this.allPlayer[0].acc_number;
        this.bank_name = this.allPlayer[0].bank_name;
        this.player_address = this.allPlayer[0].player_address;

        this.corres_bank_name = this.allPlayer[0].corres_bank_name;
        this.corres_acc_number = this.allPlayer[0].corres_acc_number;
        this.corres_bank_address = this.allPlayer[0].corres_bank_address;
        this.foreign_tax_num = this.allPlayer[0].foreign_tax_num;
        this.country_birth = this.allPlayer[0].country_birth;
        this.jersey_size = this.allPlayer[0].jersey_size;
        this.jersey_name = this.allPlayer[0].jersey_name;
        this.jersey_sleeves = this.allPlayer[0].jersey_sleeves;
        this.jersey_number = this.allPlayer[0].jersey_number;
        this.jersey_number2 = this.allPlayer[0].jersey_number2;
        this.jersey_number3 = this.allPlayer[0].jersey_number3;
        this.track_size = this.allPlayer[0].track_size;
        this.track_length = this.allPlayer[0].track_length;

      }
    })
  }
}
