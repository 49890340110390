import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment'
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseurl: any;

  constructor(public http: HttpClient, public route: Router) {
    this.baseurl = environment.baseurl;
  }
  // ----------------This is for token intercepter
  getToken() {
    return localStorage.getItem('token');
  }
  // ------------------------This is for auth
  loggedIn() {
    return !!localStorage.getItem('currentUser');
  }
  loggedInAgent() {
    return !!localStorage.getItem('agentUser');
  }
  loggedInIcc() {
    return !!localStorage.getItem('iccUser');
  }
  // -------------END
  postApi(url, data): Observable<any> {
    return this.http.post(this.baseurl + url, data)
  }

  getApi(url): Observable<any> {
    return this.http.get(this.baseurl + url)
  }
  goToBackpage(route) {
    this.route.navigate([route]);
  }
}
