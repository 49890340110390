import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-agentheader',
  templateUrl: './agentheader.component.html',
  styleUrls: ['./agentheader.component.css']
})
export class AgentheaderComponent implements OnInit {

  valueData: any;

  constructor(public _authentication: AuthService, private _router: Router) {
    this._authentication.activeCastSideAgent.subscribe((data) => {
      this.valueData = data;
    })
  }

  agentData: any;
  agentEmail: any;
  ngOnInit() {
    this.agentData = JSON.parse(localStorage.getItem('agentUser'));
    this.agentEmail = this.agentData.agent_email;
  }
  logout() {
    localStorage.clear();
    this._router.navigate(['/registration']);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }
  showMenu: boolean = false;
  tabOpen() {
    this.showMenu = !this.showMenu
  }
}
