import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthserviceService } from '../../serviceauth/authservice.service';

@Component({
  selector: 'app-iccsidebar',
  templateUrl: './iccsidebar.component.html',
  styleUrls: ['./iccsidebar.component.css']
})
export class IccsidebarComponent implements OnInit {
  valueData: any;

  constructor(public _authentication: AuthserviceService, private _router: Router) {
    this._authentication.activeCastSideicc.subscribe((data) => {
      this.valueData = data;
    })
  }

  ngOnInit() {
  }
  setHeaderDataAgent(data) {
    this._authentication.headerforicc.next(data) //this is used for set
  }
  logout() {
    localStorage.clear();
    this._router.navigate(['/icclogin']);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }
}
