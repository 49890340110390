import { Component, OnInit } from '@angular/core';
import { AllapiService } from 'src/app/admin/server/allapi.service';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  allTeams: any = [];
  yearTeam: any = [];
  productservices: any;
  YearS: any = [];
  productservices1: any;
  allPartners: any = [];
  allContactList: any = [];
  productservices2: void;
  imgsrc: string;
  playerData: any = [];
  agentData: any = [];
  productservices3: void;
  productservices4: void;
  allreq: any = [];
  productservices5: void;

  constructor(public server: AllapiService, private comS: CommonService) {
    this.server.active.next('Dashboard');
    this.server.header.next("Dashboard");
  }
  ngOnInit() {
    this.playerFetch();
    this.agentFetch();
    this.fetchTeams();
    this.fetchPartners();
    this.fetchContacts();
    this.fetchRequest();
    this.imgsrc = this.comS.partnerSlider;
  }
  fetchTeams() {
    this.server.getApi('G1').subscribe((res) => {
      // console.log("Team Name", res.response)
      this.allTeams = res.response;
      this.yearTeam = [];
      for (let data of this.allTeams) {
        this.yearTeam.push(data.team_Year)
      }
      // console.log(this.yearTeam)
      // https://stackoverflow.com/questions/840781/get-all-non-unique-values-i-e-duplicate-more-than-one-occurrence-in-an-array
      var uniq = this.yearTeam.map((name) => {
        return {
          count: 1,
          name: name
        }
      }).reduce((a, b) => {
        a[b.name] = (a[b.name] || 0) + b.count
        return a
      }, {})
      this.YearS = Object.keys(uniq).filter((a) => uniq[a] > 1)
      this.selectedTeamYears(this.YearS[0]);
    })
  }
  SelectedYear: any = []
  selectedTeamYears(year) {
    this.SelectedYear = [];
    this.allTeams.forEach(element => {
      if (element.team_Year == year) {
        this.SelectedYear.push(element)
      }
    });
    // console.log(this.SelectedYear)
  }
  fetchPartners() {
    this.server.getApi('G9').subscribe((res) => {
      this.allPartners = res.response;
    })
  }
  fetchContacts() {
    this.server.getApi('G1813').subscribe((data) => {
      this.allContactList = data.response
    })
  }
  playerFetch() {
    this.server.getApi('M1').subscribe((data) => {
      if (data.responseCode == 200) {
        this.playerData = data.response;
      }
    })
  }
  agentFetch() {
    this.server.getApi('M2').subscribe((data) => {
      if (data.responseCode == 200) {
        this.agentData = data.response;
      }
    })
  }
  fetchRequest() {
    this.server.getApi('M3').subscribe((res) => {
      if (res.responseCode == 200) {
        this.allreq = res.response;
      }
    })
  }
  ngOnDestroy() {
    this.productservices = this.server.getApi('G1').subscribe((res) => {
      this.allTeams = res.response;
    }).unsubscribe();
    this.productservices1 = this.server.getApi('G9').subscribe((res) => {
      this.allPartners = res.response;
    }).unsubscribe();
    this.productservices2 = this.server.getApi('G1813').subscribe((res) => {
      this.allContactList = res.response;
    }).unsubscribe();
    this.productservices3 = this.server.getApi('M1').subscribe((res) => {
      this.playerData = res.response;
    }).unsubscribe();
    this.productservices4 = this.server.getApi('M2').subscribe((res) => {
      this.agentData = res.response;
    }).unsubscribe();
    this.productservices5 = this.server.getApi('M3').subscribe((res) => {
      this.allreq = res.response;
    }).unsubscribe();
  }

}
