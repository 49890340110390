import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { AgentheaderComponent } from '../../shared/agentheader/agentheader.component';
import { AgentfooterComponent } from '../../shared/agentfooter/agentfooter.component';
import { AgentleftsidebarComponent } from '../../shared/agentleftsidebar/agentleftsidebar.component';
import { AgentDashboardComponent } from '../../component/agent-dashboard/agent-dashboard.component';
import { AgentprofileComponent } from '../../component/agentprofile/agentprofile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    AgentheaderComponent,
    AgentfooterComponent,
    AgentleftsidebarComponent,
    AgentDashboardComponent,
    AgentprofileComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    AgentheaderComponent,
    AgentfooterComponent,
    AgentleftsidebarComponent,
    AgentDashboardComponent,
    AgentprofileComponent,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class AgentmoduleModule { }
