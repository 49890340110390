import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { validation } from '../../../pattern'
import { ToastrService } from 'ngx-toastr';
declare var $: any
@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './agent-dashboard.component.html',
  styleUrls: ['./agent-dashboard.component.css']
})
export class AgentDashboardComponent implements OnInit {
  contactForm: FormGroup;


  constructor(public _authentication: AuthService, private toastr: ToastrService) {
    this._authentication.activeSideBaragent.next('Agent Dashboard');
    this._authentication.activeSideBaragent.next("Agent Dashboard");
    this.contactForm = new FormGroup({
      firstname: new FormControl('', [Validators.required, Validators.pattern(validation.name)]),
      lastName: new FormControl('', [Validators.required, Validators.pattern(validation.name)]),
      email: new FormControl('', [Validators.required, Validators.pattern(validation.email)]),
      phone: new FormControl('', [Validators.required, Validators.pattern(validation.number)]),
      message: new FormControl('', [Validators.required])
    })
  }
  get f() {
    return this.contactForm.controls;
  }
  agentData: any;
  agentEmail: any;
  ngOnInit() {
    this.agentData = JSON.parse(localStorage.getItem('agentUser'));
    this.agentEmail = this.agentData.agent_email;
    this.validation();
  }

  onSubmit() {
    if (this.contactForm.invalid) {
      Object.keys(this.contactForm.controls).forEach(field => {
        const control = this.contactForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
    else {
      this._authentication.postMethod('P22', this.contactForm.value).subscribe((res) => {
        if (res.responseCode == 200) {
          this.toastr.success(res['responseMessage']);
          this.contactForm.reset();
        }
        else {
          this.toastr.error(res['responseMessage']);
        }
      })
    }
  }
  validation() {
    $("#firstname,#lastName").keypress(function (e) {
      var keyCode = e.which;
      if (!((keyCode >= 65 && keyCode <= 90)
        || (keyCode >= 97 && keyCode <= 122))
        && keyCode != 8 && keyCode != 32) {
        e.preventDefault();
      }
    });
    $("#phone").keypress(function (e) {
      var keycode = e.which;
      if (!(keycode >= 48 && keycode <= 57)) {
        e.preventDefault();
      }
    });
  }

}
