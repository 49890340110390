import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
// -------------------Admin
import { HeaderComponentAdmin } from "./admin/navbar/header/header.component";
import { LeftsidebarComponentAdmin } from "./admin/navbar/leftsidebar/leftsidebar.component";
import { FooterComponentAdmin } from "./admin/navbar/footer/footer.component";
import { DashboardComponent } from "./admin/page/dashboard/dashboard.component";

// -------------------Player

import { PlayerheaderComponent } from "./player/shared/playerheader/playerheader.component";
import { PlayersidebarComponent } from "./player/shared/playersidebar/playersidebar.component";
import { PlayerfooterComponent } from "./player/shared/playerfooter/playerfooter.component";
import { PlayerdashboardComponent } from "./player/component/playerdashboard/playerdashboard.component";
import { PlayerprofileComponent } from "./player/component/playerprofile/playerprofile.component";

// ---------------------Agent
import { AgentheaderComponent } from "./agent/shared/agentheader/agentheader.component";
import { AgentfooterComponent } from "./agent/shared/agentfooter/agentfooter.component";
import { AgentleftsidebarComponent } from "./agent/shared/agentleftsidebar/agentleftsidebar.component";
import { AgentDashboardComponent } from "./agent/component/agent-dashboard/agent-dashboard.component";
import { AgentprofileComponent } from "./agent/component/agentprofile/agentprofile.component";
// -------------------------Icc
import { IccsidebarComponent } from "./icc/shared/iccsidebar/iccsidebar.component";
import { IccheaderComponent } from "./icc/shared/iccheader/iccheader.component";
import { IccfooterComponent } from "./icc/shared/iccfooter/iccfooter.component";
import { IccloginComponent } from "./icc/component/icclogin/icclogin.component";
import { IccdashboardComponent } from "./icc/component/iccdashboard/iccdashboard.component";
import { AllplayerlistComponent } from "./icc/component/allplayerlist/allplayerlist.component";
import { AllagentlistComponent } from "./icc/component/allagentlist/allagentlist.component";
// --------------------------GT20
import { Gt20leftsidebarComponent } from "./gt20canada/shared/gt20leftsidebar/gt20leftsidebar.component";
import { Gt20headerComponent } from "./gt20canada/shared/gt20header/gt20header.component";
import { Gt20footerComponent } from "./gt20canada/shared/gt20footer/gt20footer.component";
import { Gt20loginComponent } from "./gt20canada/page/gt20login/gt20login.component";
import { Gt20dashboardComponent } from "./gt20canada/page/gt20dashboard/gt20dashboard.component";
import { Gt20playerlistComponent } from "./gt20canada/page/gt20playerlist/gt20playerlist.component";
import { Gt20agentlistComponent } from "./gt20canada/page/gt20agentlist/gt20agentlist.component";
// ---------------------All auth
import { AuthgaurdGuard } from "./auth/player/authgaurd.guard";
import { AuthgaurdagentGuard } from "./auth/agent/authgaurdagent.guard";
import { AuthgaurdadminGuard } from "./auth/admin/authgaurdadmin.guard";
import { IccauthGuard } from "./auth/icc/iccauth.guard";
import { Gt20authGuard } from "./auth/gt20/gt20auth.guard";

const routes: Routes = [
  { path: "", redirectTo: "registration", pathMatch: "full" },
  { path: "", redirectTo: "playerregistration", pathMatch: "full" },
  { path: "", redirectTo: "agentregistration", pathMatch: "full" },
  {
    path: "registration",
    loadChildren: () =>
      import("./register/registration/registration.module").then(
        (m) => m.RegistrationModule
      ),
  },
  //! [REGISTRATIONPURPOSE]  search in whole project which is need for player registration
  {
    path: "playerregistration",
    loadChildren: () =>
      import("./register/playerregistration/playerregistration.module").then(
        (m) => m.PlayerregistrationModule
      ),
  },
  // { path: 'anujchaubey', loadChildren: () => import('./register/player-registration-new/player-registration-new-routing.module').then(m => m.PlayerRegistrationNewRoutingModule) },
  // ! [REGISTRATIONPURPOSE]  search in whole project which is need for player registration 
  {
    path: "agentregistration",
    loadChildren: () =>
      import("./register/agentregistration/agentregistration.module").then(
        (m) => m.AgentregistrationModule
      ),
  },

  // -------------Admin-----------------
  {
    path: "login",
    loadChildren: () =>
      import("./admin/page/adminlogin/adminlogin.module").then(
        (m) => m.AdminloginModule
      ),
  },
  {
    path: "headerBarAdmin",
    canActivate: [AuthgaurdadminGuard],
    component: HeaderComponentAdmin,
  },
  {
    path: "leftsidebarAdmin",
    canActivate: [AuthgaurdadminGuard],
    component: LeftsidebarComponentAdmin,
  },
  {
    path: "footerBarAdmin",
    canActivate: [AuthgaurdadminGuard],
    component: FooterComponentAdmin,
  },
  {
    path: "dashboard",
    canActivate: [AuthgaurdadminGuard],
    component: DashboardComponent,
  },
  {
    path: "media",
    canActivate: [AuthgaurdadminGuard],
    loadChildren: () =>
      import("./admin/page/gt20/media/media.module").then((m) => m.MediaModule),
  },
  {
    path: "request",
    canActivate: [AuthgaurdadminGuard],
    loadChildren: () =>
      import("./admin/page/gt20/teams/teams.module").then((m) => m.TeamsModule),
  },
  {
    path: "registrationdetails",
    canActivate: [AuthgaurdadminGuard],
    loadChildren: () =>
      import("./admin/page/gt20/matchcenter/matchcenter.module").then(
        (m) => m.MatchcenterModule
      ),
  },
  {
    path: "settings",
    canActivate: [AuthgaurdadminGuard],
    loadChildren: () =>
      import("./admin/page/settings/settings.module").then(
        (m) => m.SettingsModule
      ),
  },
  {
    path: "gt20",
    canActivate: [AuthgaurdadminGuard],
    loadChildren: () =>
      import("./admin/page/gt20/gt20data/gt20data.module").then(
        (m) => m.Gt20dataModule
      ),
  },

  // ---------------------Player
  {
    path: "headerBarPlayer",
    component: PlayerheaderComponent,
    canActivate: [AuthgaurdGuard],
  },
  {
    path: "sideBarPlayer",
    component: PlayersidebarComponent,
    canActivate: [AuthgaurdGuard],
  },
  {
    path: "footerBarPlayer",
    component: PlayerfooterComponent,
    canActivate: [AuthgaurdGuard],
  },
  {
    path: "playerdashboard",
    component: PlayerdashboardComponent,
    canActivate: [AuthgaurdGuard],
  },
  {
    path: "playerprofile",
    component: PlayerprofileComponent,
    canActivate: [AuthgaurdGuard],
  },

  // // ---------------------Agent
  {
    path: "headerBarAgent",
    component: AgentheaderComponent,
    canActivate: [AuthgaurdagentGuard],
  },
  {
    path: "sideBarAgent",
    component: AgentleftsidebarComponent,
    canActivate: [AuthgaurdagentGuard],
  },
  {
    path: "footerBarAgent",
    component: AgentfooterComponent,
    canActivate: [AuthgaurdagentGuard],
  },
  {
    path: "agentdashboard",
    component: AgentDashboardComponent,
    canActivate: [AuthgaurdagentGuard],
  },
  //! [REGISTRATIONPURPOSE]  search in whole project which is need for player registration
  {
    path: "addplayer",
    canActivate: [AuthgaurdagentGuard],
    loadChildren: () =>
      import("./agent/component/addplayerbyagent/addplayerbyagent.module").then(
        (m) => m.AddplayerbyagentModule
      ),
  },
  {
    path: "agentprofile",
    component: AgentprofileComponent,
    canActivate: [AuthgaurdagentGuard],
  },
  {
    path: "viewplayerlist",
    canActivate: [AuthgaurdagentGuard],
    loadChildren: () =>
      import("./agent/component/viewplayerlist/viewplayerlist.module").then(
        (m) => m.ViewplayerlistModule
      ),
  },

  //----------------------------------Icc
  {
    path: "iccsidebar",
    canActivate: [IccauthGuard],
    component: IccsidebarComponent,
  },
  {
    path: "iccheaderbar",
    canActivate: [IccauthGuard],
    component: IccheaderComponent,
  },
  {
    path: "iccfooterbar",
    canActivate: [IccauthGuard],
    component: IccfooterComponent,
  },
  { path: "icclogin", component: IccloginComponent },
  {
    path: "iccdashboard",
    canActivate: [IccauthGuard],
    component: IccdashboardComponent,
  },
  {
    path: "playerlist",
    canActivate: [IccauthGuard],
    component: AllplayerlistComponent,
  },
  {
    path: "agentlist",
    canActivate: [IccauthGuard],
    component: AllagentlistComponent,
  },

  //----------------------------------gt20Canada
  {
    path: "gt20sidebar",
    canActivate: [Gt20authGuard],
    component: Gt20leftsidebarComponent,
  },
  {
    path: "gt20headerbar",
    canActivate: [Gt20authGuard],
    component: Gt20headerComponent,
  },
  {
    path: "gt20footerbar",
    canActivate: [Gt20authGuard],
    component: Gt20footerComponent,
  },
  { path: "cricketcanadalogin", component: Gt20loginComponent },
  {
    path: "gt20dashboard",
    canActivate: [Gt20authGuard],
    component: Gt20dashboardComponent,
  },
  {
    path: "allplayerlist",
    canActivate: [Gt20authGuard],
    component: Gt20playerlistComponent,
  },
  {
    path: "allagentlist",
    canActivate: [Gt20authGuard],
    component: Gt20agentlistComponent,
  },

  {
    path: "pagenotfound",
    loadChildren: () =>
      import("./admin/page/pagenotfound/pagenotfound.module").then(
        (m) => m.PagenotfoundModule
      ),
  },
  { path: "**", redirectTo: "pagenotfound" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
