import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gt20footer',
  templateUrl: './gt20footer.component.html',
  styleUrls: ['./gt20footer.component.css']
})
export class Gt20footerComponent implements OnInit {
  currentYear: number = new Date().getFullYear();
  constructor() { }

  ngOnInit() {
  }

}
