import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthserviceService {

  Apibaseurl = environment.baseurl;

  public headerforPlayer: BehaviorSubject<any> = new BehaviorSubject(0); //it is called subject behavior for header
  public headerCastPlayer = this.headerforPlayer.asObservable()

  public activeSideBar: BehaviorSubject<any> = new BehaviorSubject(0); //it is called subject behavior for active
  public activeCastSideBarData = this.activeSideBar.asObservable()

  constructor(public _http: HttpClient, public _route: Router) { }

  postApiMethod(url, data): Observable<any> {
    return this._http.post(this.Apibaseurl + url, data)
  }

  getApiMethod(url): Observable<any> {
    return this._http.get(this.Apibaseurl + url)
  }

  goToPage(route) {
    this._route.navigate([route]);
  }
}
