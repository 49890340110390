import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PlayerheaderComponent } from '../../shared/playerheader/playerheader.component';
import { PlayerfooterComponent } from '../../shared/playerfooter/playerfooter.component';
import { PlayersidebarComponent } from '../../shared/playersidebar/playersidebar.component';
import { PlayerdashboardComponent } from '../../component/playerdashboard/playerdashboard.component';
import { PlayerprofileComponent } from '../../component/playerprofile/playerprofile.component';


@NgModule({
  declarations: [
    PlayerheaderComponent,
    PlayerfooterComponent,
    PlayersidebarComponent,
    PlayerdashboardComponent,
    PlayerprofileComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    PlayerheaderComponent,
    PlayerfooterComponent,
    PlayersidebarComponent,
    PlayerdashboardComponent,
    PlayerprofileComponent,

  ]
})
export class PlayermoduleModule { }
