import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iccfooter',
  templateUrl: './iccfooter.component.html',
  styleUrls: ['./iccfooter.component.css']
})
export class IccfooterComponent implements OnInit {
  currentYear: number = new Date().getFullYear();
  constructor() { }

  ngOnInit() {
  }

}
