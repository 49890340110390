import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthserviceService } from 'src/app/icc/serviceauth/authservice.service';

@Component({
  selector: 'app-gt20login',
  templateUrl: './gt20login.component.html',
  styleUrls: ['./gt20login.component.scss']
})
export class Gt20loginComponent implements OnInit {

  registerForm: FormGroup;

  constructor(private _server: AuthserviceService, private router: Router, private toastr: ToastrService) {
    this.reactiveForm();
  }
  reactiveForm() {
    this.registerForm = new FormGroup({
      AdminEmail: new FormControl('', [Validators.required]),
      Password: new FormControl('', [Validators.required])
    })
  }
  get f() {
    return this.registerForm.controls;
  }
  ngOnInit() {
  }
  onSubmit() {
    if (this.registerForm.invalid) {
      Object.keys(this.registerForm.controls).forEach(field => {
        const control = this.registerForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }
    else {
      this._server.postMethodicc("P1", this.registerForm.value).subscribe((res) => {
        // console.log(res)
        if (res.responseCode == 200) {
          if (res.resultinsert['admin_type'] == 3) {
            localStorage.setItem('gt20User', JSON.stringify(res.resultinsert));
            localStorage.setItem('token', res.admintoken);
            this.toastr.success(res['responseMessage']);
            setTimeout(() => {
              this.router.navigate(['/gt20dashboard']);
            }, 1000);
          } else {
            this.toastr.error("Please Check username & password!! :(");
          }
        } else {
          this.toastr.error(res['responseMessage']);
          this.router.navigate(['/cricketcanadalogin']);
        }
      })
    }
  }


}
