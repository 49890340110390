import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthserviceService } from '../../service/authservice.service';

@Component({
  selector: 'app-playersidebar',
  templateUrl: './playersidebar.component.html',
  styleUrls: ['./playersidebar.component.css']
})
export class PlayersidebarComponent implements OnInit {

  valueData: any;

  constructor(public _auth: AuthserviceService, private _router: Router) {
    this._auth.activeCastSideBarData.subscribe((data) => {
      this.valueData = data;
    })
  }

  ngOnInit() {
  }
  setHeaderData(data) {
    this._auth.headerforPlayer.next(data) //this is used for set
  }
  logout() {
    localStorage.clear();
    this._router.navigate(['/registration']);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }
}
