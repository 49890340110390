import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-agentleftsidebar',
  templateUrl: './agentleftsidebar.component.html',
  styleUrls: ['./agentleftsidebar.component.css']
})
export class AgentleftsidebarComponent implements OnInit {

  valueData: any;

  constructor(public _authentication: AuthService, private _router: Router) {
    this._authentication.activeCastSideAgent.subscribe((data) => {
      this.valueData = data;
    })
  }

  ngOnInit() {
  }
  setHeaderDataAgent(data) {
    this._authentication.headerforAgent.next(data) //this is used for set
  }
  logout() {
    localStorage.clear();
    this._router.navigate(['/registration']);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }
}
