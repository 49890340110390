import { Component, HostListener } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'GT20Admin';
  showMenu: boolean = false;
  showContent: boolean = false;

  adminlogin: boolean = false;
  playerlogin: boolean = false;
  agentlogin: boolean = false;
  icclogin: boolean = false;
  gt20login: boolean = false;

  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault();
  }

  constructor(public router: Router) {
    router.events.forEach((response) => {
      if (response instanceof NavigationStart) {
        // console.log(response.url)
        if (response.url === '/registration' || response.url === '/playerregistration' || response.url === '/anujchaubey' || response.url === '/pagenotfound' || response.url === '/agentregistration' || response.url === '/login' || response.url === '/icclogin' || response.url === '/cricketcanadalogin' || response.url === '/' || response.url === '#') {
          this.showContent = true;
          this.showMenu = false;
          // console.log(localStorage)
        } else {
          this.showMenu = true;
          this.showContent = false;
        }
      }
      //All Panel
      if (response instanceof NavigationStart) {
        if (response.url === '/dashboard' || response.url === '/media' || response.url === '/settings' || response.url === '/gt20' || response.url === '/registrationdetails' || response.url === '/request') {
          this.adminlogin = true;
        }
        if (response.url === '/playerdashboard' || response.url === '/playerprofile') {
          this.playerlogin = true;
        }
        if (response.url === '/agentdashboard' || response.url === '/agentprofile' || response.url === '/viewplayerlist' || response.url === '/addplayer') {
          this.agentlogin = true;
          // || response.url === '/addplayer'
        }
        if (response.url === '/iccdashboard' || response.url === '/playerlist' || response.url === '/agentlist') {
          this.icclogin = true;
        }
        if (response.url === '/gt20dashboard' || response.url === '/allplayerlist' || response.url === '/allagentlist') {
          this.gt20login = true;
        }
      }
    });
  }
}
