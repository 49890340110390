import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/autherization/server/api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthgaurdagentGuard implements CanActivate {
  constructor(private _serverD: ApiService, private _router: Router) { }

  canActivate(): boolean {
    if (this._serverD.loggedInAgent()) {
      return true;
    } else {
      this._router.navigate(['/registration'])
      return false;
    }
  }

}
