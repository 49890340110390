import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthserviceService {
  apibaseurlicc = environment.baseurl;

  public headerforicc: BehaviorSubject<any> = new BehaviorSubject(0); //it is called subject behavior for header
  public headerCasticc = this.headerforicc.asObservable()

  public activeSideBaricc: BehaviorSubject<any> = new BehaviorSubject(0); //it is called subject behavior for active
  public activeCastSideicc = this.activeSideBaricc.asObservable()

  constructor(public _http: HttpClient, public _route: Router) { }

  postMethodicc(url, data): Observable<any> {
    return this._http.post(this.apibaseurlicc + url, data)
  }

  getMethodicc(url): Observable<any> {
    return this._http.get(this.apibaseurlicc + url)
  }

  goToredirect(route) {
    this._route.navigate([route]);
  }
}
