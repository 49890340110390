import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderComponentAdmin } from '../../navbar/header/header.component';
import { FooterComponentAdmin } from '../../navbar/footer/footer.component';
import { LeftsidebarComponentAdmin } from '../../navbar/leftsidebar/leftsidebar.component';
import { DashboardComponent } from '../../page/dashboard/dashboard.component';



@NgModule({
  declarations: [
    HeaderComponentAdmin,
    FooterComponentAdmin,
    LeftsidebarComponentAdmin,
    DashboardComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    HeaderComponentAdmin,
    FooterComponentAdmin,
    LeftsidebarComponentAdmin,
    DashboardComponent
  ]
})
export class AdminmoduleModule { }
