import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  ApibaseurlAgent = environment.baseurl;

  public headerforAgent: BehaviorSubject<any> = new BehaviorSubject(0); //it is called subject behavior for header
  public headerCastAgent = this.headerforAgent.asObservable()

  public activeSideBaragent: BehaviorSubject<any> = new BehaviorSubject(0); //it is called subject behavior for active
  public activeCastSideAgent = this.activeSideBaragent.asObservable()

  constructor(public _http: HttpClient, public _route: Router) { }

  postMethod(url, data): Observable<any> {
    return this._http.post(this.ApibaseurlAgent + url, data)
  }

  getMethod(url): Observable<any> {
    return this._http.get(this.ApibaseurlAgent + url)
  }

  goToredirect(route) {
    this._route.navigate([route]);
  }
}
