import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IccsidebarComponent } from '../../shared/iccsidebar/iccsidebar.component';
import { IccheaderComponent } from '../../shared/iccheader/iccheader.component';
import { IccfooterComponent } from '../../shared/iccfooter/iccfooter.component';
import { IccdashboardComponent } from '../../component/iccdashboard/iccdashboard.component';
import { IccloginComponent } from '../../component/icclogin/icclogin.component';
import { AllplayerlistComponent } from '../../component/allplayerlist/allplayerlist.component';
import { AllagentlistComponent } from '../../component/allagentlist/allagentlist.component';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination'; //npm install ngx-pagination --save
import { Ng2SearchPipeModule } from 'ng2-search-filter'; //npm i ng2-search-filter Search data purpose


@NgModule({
  declarations: [
    IccsidebarComponent,
    IccheaderComponent,
    IccfooterComponent,
    IccdashboardComponent,
    IccloginComponent,
    AllplayerlistComponent,
    AllagentlistComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,

  ],
  exports: [
    IccsidebarComponent,
    IccheaderComponent,
    IccfooterComponent,
    IccdashboardComponent,
    IccloginComponent,
    AllplayerlistComponent,
    AllagentlistComponent,
    ReactiveFormsModule,
    FormsModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
  ]
})
export class IccModule { }
