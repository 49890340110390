import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthserviceService } from '../../service/authservice.service';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-playerheader',
  templateUrl: './playerheader.component.html',
  styleUrls: ['./playerheader.component.css']
})
export class PlayerheaderComponent implements OnInit {
  valueData: any;
  PlayerName: any;
  allPlayer: any;
  PlayerImage: any;
  imgSrc: string;

  constructor(public _auth: AuthserviceService, private _router: Router,private comS: CommonService) {
    this._auth.activeCastSideBarData.subscribe((data) => {
      this.valueData = data;
    })
  }

  ngOnInit() {
    this.imgSrc = this.comS.playerImage;
    this.fetchPlayerData();
  }
  fetchPlayerData() {
    var data = JSON.parse(localStorage.getItem('currentUser'));
    this._auth.postApiMethod('A4', data).subscribe((res) => {
      if (res.responseCode == 200) {
        this.allPlayer = res.response;
        this.PlayerName = this.allPlayer[0].first_name + ' ' + this.allPlayer[0].last_name;
        this.PlayerImage = this.allPlayer[0].p_image;
      }
    })
  }
  logout() {
    localStorage.clear();
    this._router.navigate(['/registration']);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }
  showMenu: boolean = false;
  tabOpen() {
    this.showMenu = !this.showMenu
  }
}
