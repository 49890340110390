import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ApiService } from 'src/app/autherization/server/api.service';

@Injectable({
  providedIn: 'root'
})
export class IccauthGuard implements CanActivate {
  constructor(private _iccservice: ApiService, private _routericc: Router) { }

  canActivate(): boolean {
    if (this._iccservice.loggedInIcc()) {
      return true;
    } else {
      this._routericc.navigate(['/icclogin'])
      return false;
    }
  }

}
