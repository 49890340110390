import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthserviceService } from '../../service/authservice.service';
import { CommonService } from 'src/app/common.service';

@Component({
  selector: 'app-playerdashboard',
  templateUrl: './playerdashboard.component.html',
  styleUrls: ['./playerdashboard.component.css']
})
export class PlayerdashboardComponent implements OnInit {
  allPlayer: any = [];
  ImageUrl: any;
  PlayerImage: any;
  p_proficiency: any;
  reserve_price: any;
  selcategory: any;
  first_name: any;
  last_name: any;
  batting_order: any;
  bowlling_style: any;
  batting_hand: any;
  bowlling_hand: any;
  p_dob: any;

  registerForm: FormGroup;
  submitted = false;
  PlayerDataLS: any;
  userNamePlayer: any;
  ag_name: any;

  constructor(private _auth: AuthserviceService, private toastr: ToastrService, private formBuilder: FormBuilder, private comS: CommonService) {
    this._auth.activeSideBar.next('Player Dashboard');
    this._auth.activeSideBar.next("Player Dashboard");
    this.ImageUrl = this.comS.playerImage;
  }

  ngOnInit() {
    this.fetchPlayerData();
    this.requestForm();
    this.PlayerDataLS = JSON.parse(localStorage.getItem('currentUser'));
    this.userNamePlayer = this.PlayerDataLS.player_username
  }
  requestForm() {
    this.registerForm = this.formBuilder.group({
      changetype: ['', Validators.required],
      reqchange: ['', Validators.required],
    });
  }
  get f() { return this.registerForm.controls; }
  fetchPlayerData() {
    var data = JSON.parse(localStorage.getItem('currentUser'));
    this._auth.postApiMethod('A4', data).subscribe((res) => {
      if (res.responseCode == 200) {
        this.allPlayer = res.response;
        this.PlayerImage = this.allPlayer[0].p_image;
        this.p_proficiency = this.allPlayer[0].p_proficiency;
        this.reserve_price = this.allPlayer[0].reserve_price;
        this.selcategory = this.allPlayer[0].selcategory;
        this.first_name = this.allPlayer[0].first_name;
        this.last_name = this.allPlayer[0].last_name;
        this.batting_order = this.allPlayer[0].batting_order;
        this.bowlling_style = this.allPlayer[0].bowlling_style;
        this.batting_hand = this.allPlayer[0].batting_hand;
        this.bowlling_hand = this.allPlayer[0].bowlling_hand;
        this.p_dob = this.allPlayer[0].p_dob;
        this.ag_name = this.allPlayer[0].ag_name;
      } else {
        this.toastr.error(res['responseMessage']);
      }
    })
  }
  onSubmit() {
    // ! [REGISTRATIONPURPOSE]  search in whole project which is need for player registration 
    this.toastr.warning('The registration process is currently halted. THANK YOU!');
    return;
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    else {
      Object.assign(this.registerForm.value, { "p_name": this.first_name + ' ' + this.last_name, "userName": this.userNamePlayer, "Agent": this.ag_name })
      console.log(this.registerForm.value)
      this._auth.postApiMethod('A9', this.registerForm.value).subscribe((res) => {
        console.log(res);
        if (res.responseCode == 200) {
          this.toastr.success(res['responseMessage']);
          this.registerForm.reset();
        } else {
          this.toastr.error(res['responseMessage']);
        }
      })
    }
  }
}
